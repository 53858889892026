<template>
  <div class="page">
    <BreadCrumb />

    <div class="page-body">
      <QueryModule :query-module="queryModule" @queryRes="queryRes" @reset="reset">
        <el-col :span="8">
          <el-date-picker v-model="queryModule.timeCond" :shortcuts="shortcuts" end-placeholder="结束时间" range-separator="-" start-placeholder="开始时间" type="datetimerange" />
        </el-col>
        <el-col :span="8">
          <el-form-item label="创建人"> <el-input v-model="queryModule.createBy" /> </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="用户名"> <el-input v-model="queryModule.username" /> </el-form-item>
        </el-col>
      </QueryModule>
      <el-button class="margin-10-0" size="small" type="primary" @click="addDialog"> 新增用户 </el-button>

      <el-table v-loading="loading" :data="tableData" fit stripe>
        <el-table-column :index="(currentPage - 1) * 5 + 1" label="序号" type="index" />
        <el-table-column label="用户名" prop="username" />
        <el-table-column label="昵称" prop="nickname" />
        <el-table-column label="联系方式" prop="phone" />
        <el-table-column label="创建人" prop="createBy" />
        <el-table-column label="创建时间" prop="createTime" />
        <el-table-column label="更新时间" prop="updateTime" />
        <el-table-column label="操作">
          <template #default="scope">
            <el-button size="mini" type="text" @click="editDialog(scope.row)"> 修改 </el-button>
            <el-button size="mini" type="text" @click="delUsers(scope.row)"> 删除 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-model:currentPage="currentPage"
        :page-size="pageSize"
        :total="total"
        background
        layout="total, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>

  <el-dialog v-model="dialogAdd" title="新增用户">
    <el-form ref="formUser" :model="formUser" :rules="UsersRules">
      <el-form-item :label-width="labelWidth" label="用户名" prop="username"> <el-input v-model="formUser.username" autocomplete="off" placeholder="请输入用户名" /> </el-form-item>
      <el-form-item :label-width="labelWidth" label="昵称" prop="nickname"> <el-input v-model="formUser.nickname" autocomplete="off" placeholder="请输入昵称" /> </el-form-item>
      <el-form-item :label-width="labelWidth" label="手机号" prop="phone"> <el-input v-model="formUser.phone" autocomplete="off" placeholder="请输入手机号" /> </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="addUsers('formUser')">确 定</el-button>
         <el-button @click="dialogAdd = false">取 消</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog v-model="dialogModify" title="修改用户">
    <el-form ref="formUser" :model="formUser" :rules="UsersRules">
      <el-form-item :label-width="labelWidth" label="用户名" prop="username">
        <el-input v-model="formUser.username" :disabled="true" autocomplete="off" placeholder="请输入用户名" />
      </el-form-item>
      <el-form-item :label-width="labelWidth" label="昵称" prop="nickname"> <el-input v-model="formUser.nickname" autocomplete="off" placeholder="请输入昵称" /> </el-form-item>
      <el-form-item :label-width="labelWidth" label="手机号" prop="phone"> <el-input v-model="formUser.phone" autocomplete="off" placeholder="请输入手机号" /> </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="editUsers('formUser')">确 定</el-button>
         <el-button @click="dialogModify = false">取 消</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import BreadCrumb from '/src/components/BreadCrumb';
import QueryModule from '/src/components/QueryModule';
import { mapGetters } from 'vuex';

export default {
  name: 'SystemUser',
  components: {
    BreadCrumb,
    QueryModule
  },
  data() {
    return {
      loading: true,
      tableData: '',
      ajaxPage: 0,
      currentPage: 1,
      total: 0,
      dialogAdd: false,
      dialogModify: false,
      queryModule: {
        timeCond: null,
        createBy: '',
        username: ''
      },
      labelWidth: '120px',
      formUser: {
        username: '',
        nickname: '',
        phone: ''
      },
      headers: {},
      UsersRules: {
        userId: [{ required: true, message: '用户ID不可为空', trigger: 'blur' }],
        username: [{ required: true, message: '用户名不可为空', trigger: 'blur' }],
        nickname: [{ required: true, message: '昵称不可为空', trigger: 'blur' }],
        phone: [
          { required: true, message: '手机号不可为空', trigger: 'blur' },
          {
            pattern: /^1[3|5|7|8|9]\d{9}$/,
            message: '请输入正确的号码格式',
            trigger: 'blur'
          }
        ]
      }
    };
  },
  computed: {
    ...mapGetters(['pageSize'])
  },
  mounted: function() {
    const _this = this;
    _this.userList(_this.ajaxPage, this.pageSize, _this.currentPage, _this.queryModule);
  },
  methods: {
    reset() {
      const _this = this;
      Object.keys(_this.queryModule).forEach((key) => (_this.queryModule[key] = ''));
      _this.queryModule['timeCond'] = null;
      _this.currentPage = 1;
      _this.ajaxPage = 0;
      _this.userList(_this.ajaxPage, this.pageSize, _this.currentPage, _this.queryModule);
    },
    queryRes(data) {
      const _this = this;
      _this.userList(_this.ajaxPage, this.pageSize, _this.currentPage, data);
    },
    userList(page, size, currentPage, datas) {
      const _this = this;
      datas['page'] = page;
      datas['size'] = size;
      this.$axios.POST('/api/v1/admin/user/all', datas).then((res) => {
        _this.loading = false;
        let data = res;
        if (data.code == 200) {
          _this.tableData = data.data.content;
          _this.total = data.data.totalElements;
        }
      });
    },
    addDialog() {
      this.dialogAdd = true;
      Object.keys(this.formUser).forEach((key) => (this.formUser[key] = ''));
    },
    addUsers(formUser) {
      console.log(12345);
      const _this = this;
      let { username, nickname, phone } = _this.formUser;
      _this.$refs[formUser].validate((valid) => {
        if (valid) {
          let data = {
            username: username,
            nickname: nickname,
            phone: phone
          };
          _this.$axios.PUT('/api/v1/admin/user', data).then((res) => {
            let data = res;
            if (data.code == 200) {
              _this.dialogAdd = false;
              _this.$message({
                showClose: true,
                message: '用户新增成功',
                type: 'success'
              });
              _this.userList(_this.currentPage - 1, _this.pageSize, _this.currentPage, _this.queryModule);
            } else {
              _this.dialogAdd = false;
              _this.$message({
                showClose: true,
                message: data.message,
                type: 'error'
              });
            }
          });
        } else {
          return false;
        }
      });
    },

    editDialog(row) {
      this.dialogModify = true;
      this.formUser.userId = row.userId;
      this.formUser.username = row.username;
      this.formUser.nickname = row.nickname;
      this.formUser.phone = row.phone;
    },
    editUsers(formUser) {
      const _this = this;
      let { userId, username, nickname, phone } = _this.formUser;
      _this.$refs[formUser].validate((valid) => {
        if (valid) {
          let data = {
            userId: userId,
            username: username,
            nickname: nickname,
            phone: phone
          };
          _this.$axios.PUT('/api/v1/admin/user', data).then((res) => {
            let data = res;
            if (data.code == 200) {
              _this.dialogAdd = false;
              _this.dialogModify = false;
              _this.$message({
                showClose: true,
                message: '用户修改成功',
                type: 'success'
              });
              _this.userList(_this.currentPage - 1, _this.pageSize, _this.currentPage, _this.queryModule);
            }
          });
        } else {
          return false;
        }
      });
    },
    delUsers(row) {
      const _this = this;
      this.$confirm('此操作将永久删除该数据，是否继续操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.$axios.DELETE('/api/v1/admin/user/' + row.userId).then((res) => {
          let data = res;
          if (data.code == 200) {
            _this.$message({
              showClose: true,
              type: 'success',
              message: '删除成功'
            });
            if (_this.currentPage > 1) {
              if (_this.tableData.length == 1) {
                _this.currentPage--;
              }
              _this.userList(_this.currentPage - 1, _this.pageSize, _this.currentPage, _this.queryModule);
            } else {
              _this.userList(_this.currentPage - 1, _this.pageSize, _this.currentPage, _this.queryModule);
            }
          }
        });
      });
    },
    handleCurrentChange(val) {
      const _this = this;
      _this.userList(val - 1, _this.pageSize, val, _this.queryModule);
    }
  }
};
</script>

<style></style>
